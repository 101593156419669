.browse-land .right .img-wrap img {
  height: 350px;
}
.main-home {
  background: #000600;
  overflow-x: hidden;

  .main-vision {
    .vision {
      padding: 0 240px;
      background-image: url("../../assets/images/Colorful-Dot-Matrix-World-Map-2.png");
      background-repeat: no-repeat;
      // background-size: cover;
      mix-blend-mode: screen;
      background-position: 90% 50%;

      .vision-wrap {
        height: 70vh;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          width: 50%;

          // flex: 1;
          h2 {
            max-width: 457px;
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 2.5vw;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          p {
            max-width: 531px;
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 1.3vw;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .button-wrap {
            margin-right: -30px;
            display: flex;
            align-items: center;

            .btn-1 {
              background: url("/assets/images/nav-btn.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              color: #fff;
              font-family: "Kumbh Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              border: none;
              padding: 17px 42px 17px 43px;
              background-color: transparent;
              margin-right: 35px;
              z-index: 3;
              width: 190px;

              position: relative;
              display: flex;
              align-items: center;
              justify-content: end;

              span {
                opacity: 0;
                // position: absolute;
                right: 40px;
                transition: opacity 0.5s ease, margin-left 0.5s ease;
              }

              &:hover {
                span {
                  position: relative;
                  right: unset;
                  opacity: 1;
                  margin-left: 10px;
                }
              }
            }

            .btn-2 {
              color: #fff;
              font-family: "Kumbh Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: 190px;
              border: none;
              height: 48px;
              flex-shrink: 0;
              border-radius: 57px;
              background-image: url("/assets/images/nav-btn.svg");
              background: var(
                --1,
                linear-gradient(
                  90deg,
                  rgba(18, 241, 255, 0.8) 0%,
                  rgba(250, 18, 255, 0.8) 100%
                )
              );
              z-index: 3;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: end;

              span {
                opacity: 0;
                // position: absolute;
                position: relative;
                right: 10px;
                transition: opacity 0.5s ease, margin-left 0.5s ease;
              }

              &:hover {
                span {
                  position: relative;
                  right: 10px;
                  opacity: 1;
                  margin-left: 14px;
                }
              }
            }
          }
        }

        .right {
          width: 50%;
          position: relative;

          // flex: 1;
          video {
            max-width: 70vw;
            height: 463px;
            mix-blend-mode: screen;
          }

          // .img-2-wrap {
          //     img {
          //         position: absolute;
          //         top: -17px;
          //         right: -175px;
          //         z-index: 0;
          //         mix-blend-mode: screen;
          //     }
          // }
        }
      }
    }

    .digital-land {
      // padding: 0 240px;

      .digital-land-wrap {
        // height: 60vh;
        margin-top: 62px;
        display: flex;
        align-items: center;
        gap: 90px;

        .left {
          width: 50%;

          video {
            max-width: 70vw;
            height: 463px;
            mix-blend-mode: screen;
          }
        }

        .right {
          width: 50%;

          h2 {
            max-width: 531px;
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          p {
            max-width: 531px;
            flex-shrink: 0;
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .button-wrap {
            // margin-right: -30px;
            display: flex;
            align-items: center;

            .btn-1 {
              background: url("/assets/images/nav-btn.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              color: #fff;
              font-family: "Kumbh Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              border: none;
              padding: 10px 16px 14px 15px;
              background-color: transparent;
              margin-right: 35px;
              z-index: 3;
              width: 190px;

              position: relative;
              display: flex;
              align-items: center;
              justify-content: end;

              span {
                opacity: 0;
                // position: absolute;
                right: 40px;
                transition: opacity 0.5s ease, margin-left 0.5s ease;
              }

              &:hover {
                span {
                  position: relative;
                  right: unset;
                  opacity: 1;
                  margin-left: 10px;
                }
              }
            }

            .btn-2 {
              color: #fff;
              font-family: "Kumbh Sans";
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              width: 190px;
              border: none;
              height: 48px;
              flex-shrink: 0;
              border-radius: 57px;
              background-image: url("/assets/images/nav-btn.svg");
              background: var(
                --1,
                linear-gradient(
                  90deg,
                  rgba(18, 241, 255, 0.8) 0%,
                  rgba(250, 18, 255, 0.8) 100%
                )
              );
              z-index: 3;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: end;

              span {
                opacity: 0;
                // position: absolute;
                position: relative;
                right: 10px;
                transition: opacity 0.5s ease, margin-left 0.5s ease;
              }

              &:hover {
                span {
                  position: relative;
                  right: 10px;
                  opacity: 1;
                  margin-left: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .marketplace {
    padding: 0 100px;
    padding-top: 93px;

    h1 {
      text-align: center;
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h3 {
      text-align: center;
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .trending-plots {
      margin-top: 74px;

      h3 {
        text-align: start;
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 11px;
      }

      .cards {
        display: flex;
        flex-wrap: wrap;
        flex-shrink: 0;
        justify-content: center;
        column-gap: 20px;
        row-gap: 10px;
      }
    }

    > p {
      margin-top: 42px;
      text-align: center;
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
    }

    .browse-land {
      margin-top: 78px;
      display: flex;
      justify-content: space-between;
      gap: 35px;

      .left {
        width: 50%;
        // height: 505px;
        flex-shrink: 0;
        border-radius: 8px;
        // background: #007FFF;
        // ;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 32px;
        // justify-content: space-between;
        // padding: 20px 0;

        .img-wrap {
          height: 70%;

          img {
            // display: none;
            // margin-top: 102px;
            background-size: cover;
            width: 100%;
            object-fit: cover;
            max-width:  540px;
            height: 340px;
            border-radius: 15px;
          }
        }

        .details {
          display: flex;
          align-items: center;
          // margin-top: 33px;
          padding: 0 20px;

          p {
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            // margin-right: 34px;
            margin-bottom: 0;
          }

          .btn-1 {
            background: url("/assets/images/nav-btn.svg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border: none;
            padding: 27px 31px 27px 31px;
            background-color: transparent;
            z-index: 3;
            width: 213px;
          }
        }
      }

      .right {
        width: 50%;
        // height: 505px;
        // border-radius: 8px;
        // background:#3c005a;
        // backdrop-filter: blur(50px);
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 32px;
        // justify-content: space-between;
        // padding: 20px 0;

        .img-wrap {
          height: 70%;

          img {
            // display: none;
            // margin-top: 102px;
            background-size: cover;
            width: 100%;
            object-fit: cover;
            height: 340px;
            max-width: 540px;
            border-radius: 15px;
            // padding: 2px 29px;
          }
        }

        .details {
          display: flex;
          align-items: center;
          // margin-top: 33px;
          height: 20%;
          padding: 0 20px;

          p {
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.community {
  // max-width: 1920px;
  position: relative;
  .bg-community {
    height: 100vh;
    background-image: url(../../assets/images/community-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 35%;
      background: linear-gradient(180deg, #000600 0%, rgba(0, 6, 0, 0) 100%);
      z-index: 2;
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 35%;
      background: linear-gradient(0deg, #000600 0%, rgba(0, 6, 0, 0) 100%);
      z-index: 2;
    }
  }

  .community-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 137px;
    position: absolute;
    top: 20px;

    p {
      color: #fff;
      text-align: center;
      font-family: "Kumbh Sans";
      font-size: 3.2vw;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      z-index: 2;
    }
  }

  .shad-1 {
    position: absolute;
    width: 100%;
    height: 142px;
    top: 0;
    background: linear-gradient(180deg, #000600 0%, rgba(0, 6, 0, 0) 100%);
    z-index: 2;
  }

  .shad-2 {
    position: absolute;
    width: 100%;
    height: 142px;
    bottom: 0;
    background: linear-gradient(0deg, #000600 0%, rgba(0, 6, 0, 0) 100%);
    z-index: 2;
  }
}

.main-reward {
  margin: 0 137px;

  .reward {
    // height: 100vh;
    display: flex;
    align-items: center;
    gap: 90px;

    .left {
      width: 50%;

      h2 {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 50px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .button-wrap {
        margin-right: -30px;
        display: flex;
        align-items: center;

        .btn-1 {
          background: url("/assets/images/nav-btn.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border: none;
          padding: 10px 16px 14px 15px;
          background-color: transparent;
          margin-right: 35px;
          z-index: 3;
          width: 190px;

          position: relative;
          display: flex;
          align-items: center;
          justify-content: end;

          span {
            opacity: 0;
            // position: absolute;
            right: 40px;
            transition: opacity 0.5s ease, margin-left 0.5s ease;
          }

          &:hover {
            span {
              position: relative;
              right: unset;
              opacity: 1;
              margin-left: 10px;
            }
          }
        }

        .btn-2 {
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 190px;
          height: 48px;
          flex-shrink: 0;
          border-radius: 57px;
          border: 2px solid var(--1, #12f1ff);
          background: var(
            --1,
            linear-gradient(
              90deg,
              rgba(18, 241, 255, 0.8) 0%,
              rgba(250, 18, 255, 0.8) 100%
            )
          );
        }
      }
    }

    .right {
      position: relative;
      width: 50%;

      .coin1 {
        position: absolute;
        top: 50px;
        left: 200px;
        img {
          width: 50%;
        }
      }
      .coin2 {
        position: absolute;
        bottom: 60px;
        right: 90px;
        img {
          width: 50%;
        }
      }
      video {
        max-width: 824px;
        height: 463px;
        mix-blend-mode: screen;
      }
    }
  }
}

.virtual-tour {
  display: flex;
  align-items: center;
  // margin: 0 137px;
  margin-top: 119px;
  // height: 100vh;
  padding: 0px 40px;

  .left {
    // width: 50%;
    video {
      // max-width: 524px;
      height: 463px;
      mix-blend-mode: screen;
    }
  }

  .right {
    width: 40%;
    h2 {
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .button-wrap {
      margin-right: -30px;
      display: flex;
      align-items: center;

      .btn-1 {
        background: url("/assets/images/nav-btn.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border: none;
        padding: 10px 16px 14px 15px;
        background-color: transparent;
        margin-right: 35px;
        z-index: 3;
        width: 190px;

        position: relative;
        display: flex;
        align-items: center;
        justify-content: end;

        span {
          opacity: 0;
          // position: absolute;
          right: 40px;
          transition: opacity 0.5s ease, margin-left 0.5s ease;
        }

        &:hover {
          span {
            position: relative;
            right: unset;
            opacity: 1;
            margin-left: 10px;
          }
        }
      }

      .btn-2 {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        width: 190px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 57px;
        border: none;
        background: var(
          --1,
          linear-gradient(
            90deg,
            rgba(18, 241, 255, 0.8) 0%,
            rgba(250, 18, 255, 0.8) 100%
          )
        );
      }
    }
  }
}

.main-downlod-wrap {
  display: none;
  background-color: white;
  height: 100vh;
  position: relative;

  .img1 {
    position: absolute;
    left: 0;
    z-index: 0;
    height: 100%;
  }

  .img2 {
    position: absolute;
    right: 0;
    z-index: 0;
    height: 100%;
  }

  .download-app {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 270px;

    .left {
      flex: 1;
      z-index: 1;

      h2 {
        color: #000600;
        font-family: "Kumbh Sans";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 8px;
        max-width: 520px;
      }

      p {
        color: #000600;
        font-family: "Kumbh Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 44px;
      }

      .button-wrap {
        margin-right: -30px;
        display: flex;
        flex-direction: column;

        .btn-1 {
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          border-radius: 57px;
          border: 2px solid var(--1, #12f1ff);
          background: var(
            --1,
            linear-gradient(
              90deg,
              rgba(18, 241, 255, 0.8) 0%,
              rgba(250, 18, 255, 0.8) 100%
            )
          );
          max-width: 259px;
          height: 48px;
          margin-bottom: 8px;
        }

        .btn-2 {
          font-family: "Kumbh Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          background: var(
            --1,
            linear-gradient(
              90deg,
              rgba(18, 241, 255, 0.8) 0%,
              rgba(250, 18, 255, 0.8) 100%
            )
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          border-radius: 57px;
          border: 2px solid var(--1, #12f1ff);
          max-width: 303px;
          height: 48px;
        }
      }
    }

    .right {
      flex: 1.2;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      img {
        max-width: 615px;
        height: 666px;
        flex-shrink: 0;
      }
    }
  }
}

.main-fqa {
  margin: 0 240px;

  .fqa {
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-top: 150px;
    align-items: center;
    
    h1 {
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 50px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 68px;
    }

    // .que-wrap {
    //     margin-bottom: 24px;

    //     .que {
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;
    //         margin-bottom: 19px;
    //         max-width: 1030px;
    //         height: 74px;
    //         flex-shrink: 0;
    //         display: flex;
    //         border-radius: 78px;
    //         background: #1A1A1A;
    //         padding: 0 40px;

    //         p {
    //             color: #FFF;
    //             font-family: "Kumbh Sans";
    //             font-size: 20px;
    //             font-style: normal;
    //             font-weight: 400;
    //             line-height: normal;
    //             margin-bottom: 0;
    //         }
    //     }
    // }

    .main-que {
      width: 928px;

      .que-wrap {
        margin-bottom: 19px;
        background: #1a1a1a;
        border-bottom: 0;
        border-radius: 45px;
        // border-radius: 45px 45px 0 0;

        .que-heading {
          border-radius: 78px;
          overflow: hidden;

          .que-btn {
            overflow: hidden;
            background: #1a1a1a;
            color: #fff;
            font-family: "Kumbh Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: flex;
            justify-content: space-between;
            padding-left: 47px;
            padding-right: 39px;

            p {
              margin: 0;
            }

            &::after {
              // color: white;
              // background-image: none;
              // content: "+";
              display: none;
            }
          }
        }

        .show {
          background: #1a1a1a;
          border-radius: 50px;
        }

        .accordion-collapse {
          .accordion-body {
            border-radius: 0 0 10px 10px;
            p {
              color: white;
              text-align: left;
              padding-left: 27px;
              padding-right: 24px;
            }
          }
        }
      }
    }

    .btn-wrap {
      .btn {
        color: #fff;
        font-family: Kumbh Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background: url("/assets/images/nav-btn.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border: none;
        padding: 4px 10px;
        background-color: transparent;
        z-index: 3;
        width: 140px;
        height: 45px;
      }
    }
  }
}

.newsletter {
  margin-top: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #fff;
    text-align: center;
    font-family: "Kumbh Sans";
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: #fff;
    text-align: center;
    font-family: "Kumbh Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    max-width: 545px;
    margin-bottom: 30px;
  }

  .email-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 56px;
    background: #fff;
    width: 536px;
    height: 49px;
    flex-shrink: 0;
    padding: 5px 6px 5px 29px;
    margin-bottom: 40px;

    .icon-wrap {
      position: relative;
      width: 100%;

      input {
        margin-left: 8px;
        border: none;
        padding: 0;
        padding-right: 40px;
        position: absolute;
        width: 100%;

        &:focus-visible {
          outline: none;
        }
      }

      input::placeholder {
        color: #8c8c8c;
        font-family: "Kumbh Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    > input {
      border-radius: 57px;
      background: var(
        --1,
        linear-gradient(
          90deg,
          rgba(18, 241, 255, 0.8) 0%,
          rgba(250, 18, 255, 0.8) 100%
        )
      );
      border: none;
      width: 120px;
      height: 39.184px;
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      z-index: 1;
    }
  }

  .news-line {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    img {
      width: 80%;
    }
  }
}
// .footer .footer-wrap .left .footlogo img {
//   width: 130px;
//   height: 65px;
// }
.footer {
  .footer-wrap {
    display: flex;
    justify-content: space-between;
    margin: 0 185px;

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      .contact{
        a{
          img{
            width: 20px;
            height: 20px;
          }
        }
      }
      .footlogo{
        >img{
          width: 108px;
          height: 68px;
          cursor: pointer;
        }
      }
     
      p {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        align-self: stretch;
        width: 70% !important;
        padding: 12px 0px;


        span {
          color: #aebde5;
          font-family: "Kumbh Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .contact {
        list-style-type: none;
        display: flex;
        margin-right: -8px;
        padding-left: 0;
        column-gap: 7px;

        li {
          display: flex;
          width: 48px;
          height: 48px;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          // background: var(
          //   --1,
          //   linear-gradient(
          //     90deg,
          //     rgba(18, 241, 255, 0.8) 0%,
          //     rgba(250, 18, 255, 0.8) 100%
          //   )
      
          // );
          background : #007FFF;
          margin-right: 8px;
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-around;
      flex: 1;

      > ul {
        list-style-type: none;
        // margin-bottom: -24px;

        > li {
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 24px;
          cursor: pointer;
        }

        > li:first-child {
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }
      }
    }
  }

  > p {
    color: #fff;
    font-family: "Kumbh Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0;
    padding: 10px 0px;
  }
}

.vector {
  // margin-top: 20px;

  img {
    position: relative;
    width: 100%;
    z-index: 3;
  }
}

@media only screen and (max-width: 1770px) {
  .main-home {
    .marketplace {
      
      .browse-land {
        .left {
          .details {
            p {
              font-size: 1.6rem;
            }
          }
        }

        .right {
          .details {
            p {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1570px) {
  .main-home .marketplace .trending-plots .cards {
    row-gap: 30px;
    column-gap: 50px;
}
  .main-home {
    .main-vision {
      .vision {
        padding: 0 170px;
        background-position: 130% 50%;

        .vision-wrap {
          gap: 30px;

          .left {
            h2 {
              font-size: 3vw;
            }

            p {
              font-size: 1.5vw;
            }
          }

          .right {
            video {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }

      .digital-land {
        padding: 0 60px;

        .digital-land-wrap {
          .left {
            video {
              width: 52vw;
              // object-fit: cover;
            }
          }

          .right {
            h2 {
              font-size: 3vw;
            }

            p {
              font-size: 1.5vw;
            }
          }
        }
      }
    }

    .marketplace {
      .browse-land {
        .left {
          .details {
            flex-direction: column;

            p {
              font-size: 1.5vw;
            }
          }
        }

        .right {
          .details {
            p {
              font-size: 1.5vw;
            }
          }
        }
      }
    }
  }

  .main-reward {
    padding: 0 120px;

    .reward {
      .left {
        h2 {
          font-size: 2.5vw;
        }

        p {
          font-size: 1.3vw;
        }
      }

      .right {
        video {
          width: 50vw;
          // object-fit: cover;
        }
      }
    }
  }

  .virtual-tour {
    .left {
      width: 50%;
      // padding: 10px 60px;

      video {
        width: 100%;
        height: 315px;
        // object-fit: cover;
      }
    }

    .right {
      h2 {
        font-size: 2.7rem;
      }

      p {
        font-size: 1.1rem;
      }
    }
  }

  .main-fqa {
    margin: 0 120px;

    .fqa {
      padding-top: 90px;
    }
  }

  .footer {
    .footer-wrap {
      margin: 0 130px;
    }
  }
}

@media only screen and (max-width: 1230px) {
  .main-home {
    .main-vision {
      .vision {
        background-position: 148% 50%;
        background-size: 83%;
        padding: 0 90px;

        .vision-wrap {
          .left {
            width: 40%;

            h2 {
              font-size: 1.7rem;
            }

            p {
              font-size: 1.1rem;
            }
          }

          .right {
            width: 60%;

            video {
              width: 100%;
            }
          }
        }
      }

      .digital-land {
        padding: 0 90px;

        .digital-land-wrap {
          .left {
            width: 50%;

            video {
              width: 100%;
            }
          }

          .right {
            width: 50%;

            h2 {
              font-size: 1.7rem;
            }

            p {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .marketplace {
      .browse-land {
        flex-direction: column;
        align-items: center;
        row-gap: 40px;

        .left {
          width: 100%;

          .details {
            flex-direction: row;

            p {
              font-size: 14px;
            }
          }
        }

        .right {
          width: 100%;

          .details {
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .main-reward {
    padding: 0 20px;
    margin: 0 90px;

    .reward {
      gap: 25px;

      .left {
        h2 {
          font-size: 2rem;
        }

        p {
          font-size: 0.9rem;
        }
      }

      .right {
        video {
          width: 50vw;
          // object-fit: cover;
        }
      }
    }
  }

  .virtual-tour {
    // margin: 0 110px;

    .left {
      width: 50%;

      video {
        width: 50vw;
        // object-fit: cover;
      }
    }

    .right {
      h2 {
        font-size: 2.4rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  .main-fqa {
    margin: 0 60px;

    .fqa {
      h1 {
        font-size: 37px;
      }

      .main-que {
        width: 800px;
      }
    }
  }
  .footer {
    .footer-wrap {
      margin: 0 110px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .main-home {
    .main-vision {
      .vision {
        padding: 0 20px;
      }

      .digital-land {
        padding: 0 20px;
      }
    }

    .marketplace {
      padding: 0 72px;

      > h3 {
        font-size: 1.6rem;
      }

      .trending-plots {
        display: flex;
        flex-direction: column;
        align-items: center;

        .cards {
          align-items: center;
          justify-content: center;
        }
      }

      .browse-land {
        flex-direction: column;
        align-items: center;

        .left {
          max-width: 680px;

          .details {
            flex-direction: column;
          }
        }

        .right {
          max-width: 680px;
        }
      }
    }
  }

  .community {
    .community-wrap {
      margin: 0 80px;
    }
  }

  .main-reward {
    padding: 0 10px;
    margin: 0 10px;

    .reward {
      gap: 0;

      .left {
        h2 {
          font-size: 2rem;
        }

        p {
          font-size: 0.85rem;
        }
      }

      .right {
        video {
          width: 40vw;
          // object-fit: cover;
        }
      }
    }
  }

  .virtual-tour {
    // margin: 0 0px;

    .left {
      width: 50%;

      video {
        width: 45vw;
        // object-fit: cover;
      }
    }

    .right {
      h2 {
        font-size: 2.3rem;
      }

      p {
        font-size: 1rem;
      }

      .button-wrap {
        margin-right: 0px;
      }
    }
  }

  .main-fqa {
    margin: 0 60px;

    .fqa {
      .main-que {
        width: 600px;

        .que-wrap {
          .que-heading {
            .que-btn {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    .footer-wrap {
      margin: 0 80px;
    }
  }
}

@media only screen and (max-width: 716px) {
  .main-home {
    .main-vision {
      .vision {
        background-position: 50% 85%;
        background-size: 100%;
        padding: 0 20px;

        .vision-wrap {
          flex-direction: column;
          justify-content: center;

          .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            width: 100%;

            h2 {
              font-size: 2rem;
            }

            p {
              font-size: 0.95rem;
            }
          }

          .right {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            video {
              width: 100%;
              height: unset;
            }
          }
        }
      }

      .digital-land {
        background-position: 50% 85%;
        background-size: 100%;
        padding: 0 20px;

        .digital-land-wrap {
          flex-direction: column-reverse;
          justify-content: center;
          gap: 0;

          .left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            video {
              width: 100%;
              height: unset;
            }
          }

          .right {
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            width: 100%;

            h2 {
              font-size: 2rem;
            }

            p {
              font-size: 0.95rem;
            }
          }
        }
      }
    }

    .marketplace {
      padding: 0 72px;

      .browse-land {
        .left {
          justify-content: unset;
          height: unset;
        }

        .right {
          justify-content: unset;
          height: unset;
        }
      }
    }
  }

  .community {
    .community-wrap {
      margin: 0 40px;
    }
  }

  .main-reward {
    padding: 0 10px;
    // margin: 0;

    .reward {
      flex-direction: column;
      justify-content: space-between;
      gap: 0;
      height: 80vh;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        h2 {
          font-size: 2rem;
          text-align: center;
        }

        p {
          font-size: 0.85rem;
          text-align: center;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        video {
          width: 100%;
          // object-fit: cover;
          height: unset;
        }
      }
    }
  }

  .virtual-tour {
    // margin: 0;
    flex-direction: column-reverse;
    justify-content: center;
    row-gap: 10px;
    // height: 80vh;
    margin-bottom: 30px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      h2 {
        font-size: 2rem;
        text-align: center;
      }

      p {
        font-size: 0.85rem;
        text-align: center;
      }
    }

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      video {
        width: 100%;
        // object-fit: cover;
        height: unset;
      }
    }
  }

  .main-fqa {
    margin: 0 20px;

    .fqa {
      h1 {
        font-size: 2.5rem;
      }

      .main-que {
        width: 400px;

        .que-wrap {
          .que-heading {
            .que-btn {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    .footer-wrap {
      margin: 0 20px;
      flex-direction: column;
      align-items: center;
      .left {
        align-items: center;
      }
      .right {
        margin-top: 20px;
        width: 100%;
        align-items: center;
        > ul {
          padding-left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .main-home .marketplace .browse-land .left .img-wrap img {
    // padding: 2px 26px !important;
}
  .main-home {
    .main-vision {
      .digital-land {
        padding: 0;

        .digital-land-wrap {
         
          .left{
            padding-top: 30px !important;

          }
          .right
           {
            padding: 0px 0px;
            p{
              padding: 0px 20px !important;
            }
            h2 {
              font-size: 2rem;
              padding: 4px 20px;
            }

            p {
              font-size: 13px;
              padding: 4px 20px;
            }
          }
        }
      }

      .vision {
        .vision-wrap {
          .left {
            margin-top: 100px;
            h2 {
              font-size: 2rem;
            }

            p {
              font-size: 0.85rem;
            }
          }
        }
      }
    }

    .marketplace {
      padding: 0 25px;
      h1{
        font-size: 32px !important;
    }
    h3{
      font-size: 1px !important;
  }
    }
  }

  .community {
    .community-wrap {
      margin: 0 8px;

      p {
        font-size: 1rem;
        padding: 0px 15px;
      }
    }
  }

  .main-reward {
    .reward {
      justify-content: center;
      row-gap: 30px;
      height: 100%;
      .right {
        .coin1 {
          position: absolute;
          top: 50px;
          left: 200px;
          img {
            width: 50%;
          }
        }
        .coin2 {
          position: absolute;
          bottom: 60px;
          right: 90px;
          img {
            width: 50%;
            display: none;
          }
        }
        video {
          max-width: 824px;
          height: 100%;
          mix-blend-mode: screen;
        }
      }
    }
  }
  .virtual-tour {
    // margin: 0;
    // flex-direction: column-reverse;
    // justify-content: space-between;
    // gap: 0;
    // height: 80vh;
    padding: 0 20px;
    row-gap: 40px;
  }
  .main-fqa {
    // margin: 0 10px;

    .fqa {
      h1 {
        font-size: 2rem;
      }

      .main-que {
        width: 100%;

        .que-wrap {
          .que-heading {
            .que-btn {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .newsletter {
    margin: 0 20px;

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 14px;
      padding: 0 10px;
    }

    .email-wrap {
      width: 100%;

      .icon-wrap {
        input {
          &::placeholder {
            font-size: 12px;
          }
        }
      }
    }
  }
  .footer {
    .footer-wrap {
      .left{
        // align-items: flex-start;
        p{
          padding: 5px 10px;
          width: 100% !important;
        }
      }
      .right {
        flex-direction: column;
      }
    }
  }
}
button.que-btn.accordion-button.collapsed svg.iconify.iconify--ic {
  display: block;
}
button.que-btn.accordion-button svg.iconify.iconify--ic {
  display: none;
}

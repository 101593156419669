.banner {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-image: url("../../assets/images/the-girl-avatar-in-metaverse-hd-2022-08-10-22-14-42-utc\ 1.png");
  // background: rgba(0, 0, 0, 0.40), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000600 54.17%);
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000600 54.17%);
  // background: rgba(0, 0, 0, 0.40);

  .bottom-line {
    stroke-width: 2px;
    stroke: rgba(250, 18, 255, 0.3);
    width: 1428.5px;
    height: 64.05px;
    flex-shrink: 0;
    z-index: 2;
  }

  .back-video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: brightness(50%);
    z-index: 1;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h1 {
    z-index: 3;
    color: #fff;
    text-align: center;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-family: "Poppins";
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    max-width: 960px;
    margin-top: 176px;
  }

  p {
    color: #fff;
    text-align: center;
    font-family: "Kumbh Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 3;
    max-width: 782px;
  }

  .button-wrap {
    z-index: 3;
    margin-top: 80px;
    margin-right: -30px;
    display: flex;

    .btn-1 {
      background: url("/assets/images/nav-btn.svg");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border: none;
      width: 190px;
      background-color: transparent;
      margin-right: 30px;
      z-index: 3;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 1px 25px;

      span {
        opacity: 0;
        // position: absolute;
        right: 40px;
        transition: opacity 0.5s ease, margin-left 0.5s ease;
      }

      &:hover {
        span {
          position: relative;
          right: unset;
          opacity: 1;
          margin-left: 10px;
        }
      }
    }

    .btn-2 {
      color: #fff;
      font-family: "Kumbh Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 190px;
      border: none;
      height: 48px;
      flex-shrink: 0;
      border-radius: 57px;
      background-image: url("/assets/images/nav-btn.svg");
      background: var(
        --1,
        linear-gradient(
          90deg,
          rgba(18, 241, 255, 0.8) 0%,
          rgba(250, 18, 255, 0.8) 100%
        )
      );
      z-index: 3;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 1px 25px;

      span {
        opacity: 0;
        // position: absolute;
        right: 40px;
        transition: opacity 0.5s ease, margin-left 0.5s ease;
      }

      &:hover {
        span {
          position: relative;
          right: unset;
          opacity: 1;
          margin-left: 10px;
        }
      }
    }
  }

  .download-now {
    margin-top: 41px;
  }

  .icons-wrap {
    display: flex;
    list-style-type: none;
    z-index: 3;
    margin-top: 29px;
    margin-right: -39px;

    li {
      margin-right: 39px;
    }

    .qr-icon {
      background: white;
      border-radius: 3px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 35%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000600 54.17%);
    // background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 35%;
    background: linear-gradient(
      180deg,
      rgba(16 14 14 / 55%) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
  }

  .vector {
    margin-top: 50px;

    img {
      position: relative;
      width: 100%;
      z-index: 3;
    }
  }
}

@media only screen and (max-width: 1465px) {
  .banner {
    h1 {
      font-size: 3em;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .banner {
    h1 {
      font-size: 3em;
    }
  }
}

@media only screen and (max-width: 800px) {
  .banner {
    h1 {
      font-size: 6vw;
    }

    p {
      font-size: 3vw;
    }
  }
}

@media only screen and (max-width: 600px) {
  .banner {
    .back-video {
      width: 100%;
    }
    h1{
      font-size: 20px;
    padding: 0px 20px;
    padding-top: 100px;
    }
    p{
      font-size: 14px;
    padding: 2px 23px;
    }
    .button-wrap {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      margin-right: 0;

      .btn-1 {
        margin-bottom: 6px;
        margin-right: 0;
        padding: 21px 30px;
      }
    }
  }
}

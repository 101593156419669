.card-wrap {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #161a16;
  backdrop-filter: blur(50px);
  padding: 16px 15px;
  width: 390px;
  height: fit-content;
  flex-shrink: 0;

  .top {
    max-width: 372px;
    height: 388px;
    border-radius: 8px;
    background: #9a087a;

    .top-box {
      height: 322px;
    }
    img {
      width: 100%;
      height: 100%;
    }

    .top-details {
      max-width: 372px;
      border-radius: 4px 4px 8px 8px;
      background: #000600;
      display: flex;
      flex-direction: column;
      padding: 11px 14px;

      p {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
      }

      .price-det {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p:first-child {
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
        }

        p:last-child {
          color: #fff;
          font-family: "Kumbh Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 10px 5px;

    .left-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;


      p:first-child {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p:last-child {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    .right-details {
      display: flex;
      flex-direction: column;
      align-items: end;

      p {
        color: #fff;
        font-family: "Kumbh Sans";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      .rating-wrap {
        display: flex;
        align-items: center;

        p {
          color: #4edc37;
          font-family: "Kumbh Sans";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-left: 6px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1013px) and (min-width: 941px) {
  .card-wrap {
    width: 313px;
  }
}
@media only screen and (max-width: 600px) {
  .card-wrap {
    width: 300px;
  }
}

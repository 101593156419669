.Navbar-sec {
  padding: 30px 74px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;

  .navbar-nav {
    column-gap: 60px;
  }

  .logo {
    width: 247px;
    height: 68.11px;
    object-fit: contain;
    position: relative;
    z-index: 3;
  }
  .me-auto {
    margin-right: 0 !important;
}
  a {
    color: #fff;
    font-family: "Kumbh Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover,
    &:active,
    &:focus {
      background: linear-gradient(to right, #12f1ff, #fa12ff);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
    }
  }

  .wallet-btn {
    background: url("/assets/images/nav-btn.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-family: "Kumbh Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    padding: 17px 42px 16px 43px;
    border-radius: 57px;
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(
        --1,
        linear-gradient(
          90deg,
          rgba(18, 241, 255, 0.8) 0%,
          rgba(250, 18, 255, 0.8) 100%
        )
      );
      opacity: 0;
      transition: opacity 0.3s ease; /* Transition opacity for a smooth effect */
      z-index: -1; /* Place the pseudo-element below the content */
    }

    &:hover::before {
      opacity: 1;
    }
  }
}

.navbar-toggler {
  padding: 0px;
  
}
.navbar-toggler:focus{
  border: none !important;
  outline: none !important;
}
.navbar-toggler:focus-within{
  border: none !important;
  outline: none !important;
  
}
  
  
.navbar-toggler:active{
  border: none;
  outline: none;
  
}

@media only screen and (max-width: 1465px) {
  .Navbar-sec {
    padding: 30px 60px;
    .navbar-nav {
      column-gap: 20px;
    }
  }
}

@media only screen and (max-width: 1270px) {
  .Navbar-sec {
    padding: 0px 30px;
    a {
      font-size: 14px;
    }
    .logo {
      width: 190px;
    }
    .wallet-btn {
      font-size: 16px;
    }
    .navbar-nav {
      column-gap: 15px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .Navbar-sec {
    padding: 0px 20px;

    .navbar {
      .container-fluid {
        padding: 0px;
        button{
            border: none;
            border-radius: 0;
        }
      }
    }
    .wallet-btn {
      font-size: 14px;
    }

    .logo {
      width: 130px;
      height: 60px;
      object-fit: contain;
    }
  }
}
